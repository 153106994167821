<template>
  <div>
    <b-modal
      :ref="'correspondence-modal-' + status" 
      :id="'correspondence-modal-' + status"
      title='Acknowledgement'
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <p v-if="reference"><b>Reference: </b>{{ reference }}</p>

        <b-form-group
          label="Last Contact Type"
          label-for="correspondence-input-1"
          invalid-feedback="Last Contact Type is required"
            :state="validateState('last_contact_type')"
        ><b-form-input
          id="correspondence-input-1"
          v-model="last_contact_type"
            :state="validateState('last_contact_type')"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Last Contact Date"
          label-for="correspondence-input-2"
          invalid-feedback="Last Contact Date is required"
            :state="validateState('last_contact_date')"
        ><b-form-input
          id="correspondence-input-2"
          v-model="last_contact_date"
            :state="validateState('last_contact_date')"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Message"
          label-for="correspondence-input"
          invalid-feedback="Message is required"
            :state="validateState('description')"
        >
        <b-form-textarea
            id="correspondence-input"
            v-model="description"
            :state="validateState('description')"
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'
  import { validationMixin } from 'vuelidate'
  import {required} from "vuelidate/lib/validators";

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function(word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}
  export default {
    props: {
      correspondence_type: {
        type: String,
        required: true,
      },
      reference: {
        type: String,
        required: false,
      },
      status: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        error: false,
        last_contact_type: '',
        last_contact_date: '',
        description: '',
      }
    },
    mixin: [validationMixin],
    validations() {

      return {
          last_contact_type: {
            required
          },
          last_contact_date: {
            required
          },
          description: {
            required
          },
      }

    },
    methods: {

      validateState(name) {

        if (this.$v[name]) {
          const {$dirty, $error} = this.$v[name];
          return $dirty ? !$error : null;
        }
      },
      resetModal() {
        this.last_contact_type = ''
        this.last_contact_date = ''
        this.description = ''
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {

        this.$v.$touch();

        if(this.$v.$anyError) {

          this.error = true;

          return

        } else {

          let url
          let data

          url = '/correspondence/complaint/'

          data = {
              complaint: this.$route.params.id,
              letter_type: 10,
              data: {
                  last_contact_type: this.last_contact_type,
                  last_contact_date: this.last_contact_date,
                    "free": this.description
              }
          }

          axios.post(url, data).then(
            response => {
              response
              this.resetModal()
              this.$v.$reset()

              this.$nextTick(() => {
              this.$bvModal.hide('correspondence-modal-' + this.status)
              })

            }
          ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              

              console.log(error.response)
          })

        }
        
      }
    }
  }
</script>