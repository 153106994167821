<template>
  <div>
    <b-modal
      :ref="'correspondence-modal-' + status" 
      :id="'correspondence-modal-' + status"
      title='Upheld / Not Upheld - Final Response'
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <p v-if="reference"><b>Reference: </b>{{ reference }}</p>

        <b-form-group
          label="Last Contact Type"
          label-for="correspondence-input-1"
          invalid-feedback="Last Contact Type is required"
            :state="validateState('last_contact_type')"
        ><b-form-input
          id="correspondence-input-1"
          v-model="last_contact_type"
            :state="validateState('last_contact_type')"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Last Contact Date"
          label-for="correspondence-input-2"
          invalid-feedback="Last Contact Date is required"
            :state="validateState('last_contact_date')"
        ><b-form-input
          id="correspondence-input-2"
          v-model="last_contact_date"
            :state="validateState('last_contact_date')"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Upheld?"
          label-for="correspondence-input-3"
          invalid-feedback="Upheld is required"
          :state="validateState('upheld')"
        >
          <b-form-select id="correspondence-input-3" v-model="upheld" :options="options" :state="validateState('upheld')"></b-form-select>
        </b-form-group>

        <b-form-group
          v-if="this.upheld"
          label="Apoloy for"
          label-for="correspondence-input-4"
          invalid-feedback="Apoloy for is required"
            :state="validateState('apology_for')"
        >
        <b-form-textarea
            v-if="this.upheld"
            id="correspondence-input-4"
            v-model="apology_for"
            :state="validateState('apology_for')"
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Findings and suggested resolution"
          label-for="correspondence-input-5"
          invalid-feedback="Findings and suggested resolution is required"
            :state="validateState('findings_and_suggested_resolution')"
        >
        <b-form-textarea
            v-if="this.upheld"
            id="correspondence-input-5"
            v-model="findings_and_suggested_resolution"
            :state="validateState('findings_and_suggested_resolution')"
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        </b-form-group>

        <b-form-group
          v-if="!this.upheld"
          label="Requested resolution"
          label-for="correspondence-input-6"
          invalid-feedback="Requested resolution is required"
            :state="validateState('requested_resolution')"
        >
        <b-form-textarea
            v-if="!this.upheld"
            id="correspondence-input-6"
            v-model="requested_resolution"
            :state="validateState('requested_resolution')"
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Resolution refusal reason"
          label-for="correspondence-input-7"
          invalid-feedback="Resolution refusal reason is required"
            :state="validateState('resolution_refusal_reason')"
        >
        <b-form-textarea
            v-if="!this.upheld"
            id="correspondence-input-7"
            v-model="resolution_refusal_reason"
            :state="validateState('resolution_refusal_reason')"
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Closing Paragraph"
          label-for="correspondence-input-8"
          invalid-feedback="Closing Paragraph is required"
            :state="validateState('closing_paragraph')"
        >
        <b-form-textarea
            id="correspondence-input-8"
            v-model="closing_paragraph"
            :state="validateState('closing_paragraph')"
            placeholder=""
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'
  import { validationMixin } from 'vuelidate'
  import {required, requiredIf } from "vuelidate/lib/validators";

  export default {
    props: {
      correspondence_type: {
        type: String,
        required: true,
      },
      reference: {
        type: String,
        required: false,
      },
      status: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        error: false,
        last_contact_type: '',
        last_contact_date: '',
        upheld: '',
        apology_for: '',
        findings_and_suggested_resolution: '',
        requested_resolution: '',
        resolution_refusal_reason: '',
        closing_paragraph: '',
        options: [{value: null, text: 'Please select'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]

      }
    },
    mixin: [validationMixin],
    validations() {

      return {
          last_contact_type: {
            required
          },
          last_contact_date: {
            required
          },
          upheld: {
            required
          },
          apology_for: {
            required: requiredIf(function () {
                return this.upheld
            })
          },
          findings_and_suggested_resolution: {
            required: requiredIf(function () {
                return this.upheld
            })
          },
          requested_resolution: {
            required: requiredIf(function () {
                return !this.upheld
            })
          },
          resolution_refusal_reason: {
            required: requiredIf(function () {
                return !this.upheld
            })
          },
          closing_paragraph: {}
      }

    },
    methods: {

      validateState(name) {

        if (this.$v[name]) {
          const {$dirty, $error} = this.$v[name];
          return $dirty ? !$error : null;
        }
      },
      resetModal() {
        this.last_contact_type = ''
        this.last_contact_date = ''
        this.upheld = ''
        this.apology_for = ''
        this.findings_and_suggested_resolution = ''
        this.requested_resolution = ''
        this.resolution_refusal_reason = ''
        this.closing_paragraph = ''
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {

        this.$v.$touch();

        if(this.$v.$anyError) {

          this.error = true;

          return

        } else {

          let url
          let data

          url = '/correspondence/complaint/'

          var nested_data = {}
          nested_data.last_contact_type = this.last_contact_type
          nested_data.last_contact_date = this.last_contact_date
          nested_data.upheld = this.upheld
          nested_data.closing_paragraph = this.closing_paragraph

          if (this.upheld) {
            nested_data.apology_for = this.apology_for
            nested_data.findings_and_suggested_resolution = this.findings_and_suggested_resolution
          } else {
            nested_data.resolution_refusal_reason = this.resolution_refusal_reason
            nested_data.requested_resolution = this.requested_resolution
          }

          data = {
              complaint: this.$route.params.id,
              letter_type: 11,
              data: nested_data
          }

          axios.post(url, data).then(
            response => {
              response
              this.resetModal()
              this.$v.$reset()

              // Hide the modal manually
              this.$nextTick(() => {
                this.$bvModal.hide('correspondence-modal-' + this.status)
              })

            }
          ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              

              console.log(error.response)
          })

        }
        
      }
    }
  }
</script>

