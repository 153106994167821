<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Complaint</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body >

          <b-tabs card>

            <b-tab title="General">

              <table class="table" v-if="complaint">
                <tbody>
                    <tr v-for="(v, k, index) in complaint" :key="index" v-if="k !== 'id' && k !== 'all_groups'  && k !== 'options' && k !== 'root_cause' && k !== 'complaint_type' && k !== 'status' && k !== 'area' && k !== 'channel' && k !== 'root_cause_category' && k !== 'fos_eligible' && k !== 'fos_decision' && k !== 'fca_complaint_category' && k !== 'fca_complaint_sub_category' ">
                        <th v-if="k === 'complaint_type_display'">Complaint Type</th>
                        <th v-else-if="k === 'root_cause_display'">Root Cause</th>
                        <th v-else-if="k === 'status_display'">Status</th>
                        <th v-else>{{ removePretty(k)|capitalize }}</th>
                        <td v-if="k === 'groups'">{{ userGroups }}</td>
                        <td v-if="k === 'attachments'">
                          <table class="table">
                            <tr>
                              <th>Name</th>
                              <th>Created</th>
                              <th>Actions</th>
                            </tr>
                            <tr v-for="(document, document_index) in v" :key="document_index">
                              <td>{{document.name}}</td>
                              <td>{{document.created}}</td>
                              <td><a class="btn btn-sm btn-outline-secondary" target="_blank" :href="document.link" >View</a></td>
                            </tr>
                          </table>
                        </td>
                      <td v-else>{{ v }}</td>
                    </tr>
                </tbody>
              </table>

            </b-tab>

            <correspondence-tab v-if="complaint" v-bind:type="'complaint'" v-bind:object="$route.params.id" ></correspondence-tab>

            <notes-tab v-if="complaint" v-bind:type="'complaint'" v-bind:object="$route.params.id"></notes-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>


          <b-button :to="{ name: 'ComplaintEdit', params: { id: $route.params.id } }" variant="outline-dark" class="float-right">Edit</b-button>
          <b-button v-b-modal="'task-add-task'" variant="outline-dark"  class="float-right ml-2 mr-4">Add task</b-button>

          <b-dropdown id="dropdown-dropup" dropup text="Contact Customer" variant="outline-dark" class=" ml-2 float-right">
            <b-dropdown-item href="#">Call</b-dropdown-item>
            <b-dropdown-item v-b-modal="'correspondence-modal-free-format'">Email</b-dropdown-item>
          </b-dropdown>
          
          <b-dropdown id="dropdown-dropup" dropup text="Update Status" variant="outline-dark" class=" ml-2 float-right">
            <b-dropdown-item v-b-modal="'correspondence-modal-acknowledgment'" >Acknowledgment</b-dropdown-item>
            <b-dropdown-item v-b-modal="'correspondence-modal-holding'" >4/8 Week Holding</b-dropdown-item>
            <b-dropdown-item v-b-modal="'correspondence-modal-final-response'">Upheld / Not Upheld - Final Response</b-dropdown-item>
            <b-dropdown-item v-b-modal="'correspondence-modal-summary-resolution'">Summary Resolution</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-card>
    </b-col>
  </b-row>

    <add-task v-bind:task_type="'complaint'" v-bind:reference="complaint.reference"></add-task>

    <!-- Update Status -->
    <correspondence-acknowledgment-modal v-if="loaded" v-bind:type="'complaint'" v-bind:object="complaint.id" v-bind:correspondence_type="'compliant'" v-bind:status="'acknowledgment'" v-bind:reference=complaint.reference></correspondence-acknowledgment-modal>
    <correspondence-holding-modal v-if="loaded" v-bind:type="'complaint'" v-bind:object="complaint.id" v-bind:correspondence_type="'compliant'" v-bind:status="'holding'" v-bind:reference=complaint.reference></correspondence-holding-modal>
    <correspondence-final-modal v-if="loaded" v-bind:type="'complaint'" v-bind:object="complaint.id" v-bind:correspondence_type="'compliant'" v-bind:status="'final-response'" v-bind:reference=complaint.reference></correspondence-final-modal>
    <correspondence-summary-modal v-if="loaded" v-bind:type="'complaint'" v-bind:object="complaint.id" v-bind:correspondence_type="'compliant'" v-bind:status="'summary-resolution'" v-bind:reference=complaint.reference></correspondence-summary-modal>

    <!-- Contact Customer -->
    <contact-customer-modal v-if="loaded" v-bind:type="'complaint'" v-bind:object="complaint.id" v-bind:correspondence_type="'compliant'" v-bind:status="'free-format'" v-bind:reference=complaint.reference></contact-customer-modal>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import addTask from '../task/add-task-modal'
  import correspondenceTab from '../utils/tabs/correspondence'
  import correspondenceAcknowledgmentModal from '../complaints/utils/acknowledgment'
  import correspondenceHoldingModal from '../complaints/utils/holding'
  import correspondenceSummaryModal from '../complaints/utils/summary-resolution'
  import correspondenceFinalModal from '../complaints/utils/final-response'
  import ContactCustomerModal from '../utils/modal/contact-customer'
  import notesTab from '../utils/tabs/notes'

  export default {
    name: 'User',
    components: {Id, addTask,
      'correspondence-tab': correspondenceTab,
      'contact-customer-modal': ContactCustomerModal,
      'correspondence-acknowledgment-modal': correspondenceAcknowledgmentModal,
      'correspondence-holding-modal': correspondenceHoldingModal,
      'correspondence-summary-modal': correspondenceSummaryModal,
      'correspondence-final-modal': correspondenceFinalModal,
      'notes-tab': notesTab,
    },
    props: {
      caption: {
        type: String,
        default: 'Complaint Details'
      },
    },
    data: () => {
      return {
        loaded: false,
        complaint: null,
        pdf: null,
        pdf_link: null,
        get_document: false
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      removePretty(data) {
        return data.replace('_pretty', '')
      },
      getComplaint() {
        axios.get('/complaints/' + this.$route.params.id + '/').then(
          response => {
            this.complaint = response.data
            this.options = response.data.options
            this.loaded = true
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.user
      }
    },
    mounted() {
      this.getComplaint()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getComplaint()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
